import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Assets from "../Images/index";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";

const Home = () => {
  const [selectedNews, setSelectedNews] = useState(null);
  const companyName = "Curiosense Innovations";
  const AboutCompany =
    "Curiosense Innovations prioritizes a play-centric, curiosity-driven approach for resilient minds. Emphasizing holistic development and mental well-being, we collaborate with parents, teachers, experts and schools to form a comprehensive learning ecosystem. With innovative tools, we prepare children not just for today but also for future opportunities, focusing on skill development and lifelong success.";

  const newsItems = [
    {
      id: 1,
      image: Assets?.n1,
      title: "Curiosense Completes Launchpad Incubation at IIMB NSRCEL",
      date: "05",
      description: "Curiosense Innovations successfully completed the 100-day Idea to Implementation Launchpad Incubation Program at IIMB NSRCEL, refining its vision and strategy. The program provided mentorship, industry insights, and expert-led sessions, empowering the venture to scale and make a meaningful impact in India's startup ecosystem."
    },
    {
      id: 2,
      image: Assets?.n2,
      title: "Spell Strikers – An Innovative Game-Based Spelling Competition",
      date: "16",
      description: "Spell Strikers is an exciting, game-based spelling competition designed to make learning enjoyable and engaging. Paired with CurioKAARDS, this innovative competition helps children enhance their spelling skills in a dynamic, interactive setting, promoting creativity, excitement, and a love for lifelong learning through play!"
    }
  ];

  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <>
      <div className="home-sec">
        <div className="home-cnt">
          <h2>
            Welcome To <br /> <span className="subHeading">{companyName}</span>
          </h2>
          <p>{AboutCompany}</p>
        </div>
        <div
          className="home-img"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="100"
          data-aos-offset="1"
        >
          <img className="animation" src={Assets?.home} alt="Home" />
        </div>
      </div>

      <div className="latest-news-sec">
        <h2 className="latest-news-title">Latest news</h2>
        <div className="latest-news-items">
          {newsItems.map((item) => (
            <div 
              key={item.id} 
              className="latest-news-item"
              onClick={() => setSelectedNews(item)}
            >
              <img src={item.image} alt={`News image ${item.id}`} />
              <div className="latest-news-details">
                <h3>{item.title}</h3>
                <p>{item.date}<sup>th</sup> February 2025 | News release</p>
              </div>
            </div>
          ))}
        </div>

        {/* Modal */}
        {selectedNews && (
          <div className="news-modal-overlay" onClick={() => setSelectedNews(null)}>
            <div className="news-modal" onClick={e => e.stopPropagation()}>
              <button className="close-button" onClick={() => setSelectedNews(null)}>×</button>
              <div className="modal-content">
                <div className="modal-image">
                  <img src={selectedNews.image} alt={selectedNews.title} />
                </div>
                <div className="modal-details">
                  <h2>{selectedNews.title}</h2>
                  <p className="modal-date">{selectedNews.date}<sup>th</sup> February 2025 | News release</p>
                  <p className="modal-description">{selectedNews.description}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;